import React from 'react';
import classes from './DashboardHeader.module.scss';
import {FaPowerOff} from 'react-icons/fa';
import Logo from '../../../assets/images/commercial prime-33.svg';
import {HiMenuAlt2, HiQrcode} from 'react-icons/hi';
import {BiDotsVerticalRounded, BiSolidUserRectangle} from 'react-icons/bi';
import {useLocation, useNavigate} from 'react-router-dom';
import Backdrop from '../../UI/Backdrop/Backdrop';
import StatusChangeConfirmation from '../DashboardBody/Home/StatusChangeConfirmation/StatusChangeConfirmation';
import LogOutConfirmation from "./LogOutConfirmation/LogOutConfirmation";
import {connect} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import SVG from 'react-inlinesvg';
import QRCodePopup from "../DashboardBody/Home/QRCodePopup/QRCodePopup";
import {Switch} from "antd";
import PrivacyMode from "../../../assets/images/highPrivacy.png";
import {changePrivacyMode} from "../../../store/actions";
import PrivacyModeChangeConfirm from "../../UI/PrivacyModeChangeConfirm/PrivacyModeChangeConfirm";
import {PROFILE_STATUS, ROUTES_PATHS} from "../../../common/enums";
import MiniLogo from '../../../assets/images/commercial-prime-logo-svg.svg';


const DashboardHeader = props => {
    const location = useLocation();
    const navigate = useNavigate();

    const [activationStatusPopupVisible, setActivationStatusPopupVisibility] =
        React.useState(false);

    const [qrCodePopupVisible, setQrCodePopupVisibility] = React.useState(false);

    const [privacyModePopupVisible, setPrivacyModePopupVisibility] = React.useState(false);

    const [logoutConfirmationPopupVisible, setLogoutConfirmationPopupVisible] = React.useState(false);

    const dashboardHeaderStyles = [classes.DashboardHeader];

    if (
        location.pathname !== `/${ROUTES_PATHS.USER.HOME}`) {
        dashboardHeaderStyles.push(classes.DashboardHeaderHidden);
    }

    if (!props.status?.hpmEnabledForTenant) {
        dashboardHeaderStyles.push(classes.TenantModeHeader)
    }

    console.log(props);

    const handleSwitchClick = () => {
        const newPrivacyMode = !props.status.highPrivacyMode;
        props.changePrivacyMode(newPrivacyMode);
    };

    console.log(props);

    return (
        <div className={dashboardHeaderStyles.join(' ')}>
            {activationStatusPopupVisible ? (
                <Backdrop onClick={() => setActivationStatusPopupVisibility(false)}>
                    <StatusChangeConfirmation
                        onClose={() => setActivationStatusPopupVisibility(false)}
                    />
                </Backdrop>
            ) : null}

            {privacyModePopupVisible ? (
                <Backdrop onClick={() => setPrivacyModePopupVisibility(false)}>
                    <PrivacyModeChangeConfirm
                        onClose={() => setPrivacyModePopupVisibility(false)}
                        themeColor={props.themeColor}
                        active={props.status.highPrivacyMode}
                        onClick={handleSwitchClick}
                    />
                </Backdrop>
            ) : null}

            {logoutConfirmationPopupVisible ? (
                <Backdrop onClick={() => setLogoutConfirmationPopupVisible(false)}>
                    <LogOutConfirmation
                        themeColor={props.themeColor}
                        onClose={() => setLogoutConfirmationPopupVisible(false)}
                    />
                </Backdrop>
            ) : null}

            {qrCodePopupVisible ? (
                <Backdrop onClick={() => setQrCodePopupVisibility(false)}>
                    <QRCodePopup
                        onClose={() => setQrCodePopupVisibility(false)}
                        themeColor={props.themeColor}
                    />
                </Backdrop>
            ) : null}

            {location.pathname === `/${ROUTES_PATHS.USER.CONTACTS}` && <div className={classes.HeaderTop}>
                <div
                    className={classes.SideButtonContainer}
                    onClick={props.toggleDropdownVisibility}
                >
                    <BiDotsVerticalRounded size={24} color="#bcbcbc"/>
                </div>
            </div>
            }

            {location.pathname === `/${ROUTES_PATHS.USER.HOME}` ? <div className={classes.HeaderTop}>
                <>
                    {props.collaboration ? (
                        <div className={classes.CollaborationWrapper}>
                            <SVG fill={props.themeColor} className={classes.Logo} src={Logo}
                                 alt={'Commercial Prime'}/>
                            <p>|</p>
                            <img src={props.logo} alt={'logo'} className={classes.Logo}/>
                        </div>
                    ) : (
                        props.logo === '' || props.logo === null ? (
                            <SVG fill={props.themeColor} className={classes.Logo} src={Logo}
                                 alt={'Commercial Prime'}/>
                        ) : (
                            <img src={props.logo} alt={'logo'} className={classes.Logo}/>
                        )
                    )}

                    <FaPowerOff
                        id="log-out-tooltip"
                        className={classes.Logout}
                        size={20}
                        onClick={() => {
                            setLogoutConfirmationPopupVisible(true)
                        }}
                    />
                </>

                <div className={classes.HamburgerContainer} onClick={props.toggleMenu}>
                    Menu
                    <HiMenuAlt2 size={24} color="#BCBCBC"/>
                </div>
            </div> : <div className={classes.HeaderTop}>
                <SVG
                    src={MiniLogo}
                    alt={"Prime Logo"}
                    className={classes.MiniLogo}
                    fill={props.themeColor}
                />
            </div>}
            {
                location.pathname === `/${ROUTES_PATHS.USER.HOME}` && <div className={classes.HeaderBottom}>
                    <div className={classes.ButtonWrapper}>
                        <div className={classes.Greeting}>
                            <h1>Hello,</h1>
                            <span style={{color: props.themeColor}}>{props.user}!</span>
                        </div>
                        <div className={classes.QrContainer}>
                            <HiQrcode className={classes.QrButton} onClick={() => setQrCodePopupVisibility(true)}/>
                            {props.isProfileStatusLoading ? (
                                <div className={classes.SpinnerContainer}>
                                    <ClipLoader themeColor={props.themeColor} loading={true} size={20}/>
                                </div>
                            ) : (
                                <div
                                    className={
                                        props.status?.profileStatus === PROFILE_STATUS.ACTIVE
                                            ? classes.AccountStatusActive
                                            : classes.AccountStatusInactive
                                    }
                                    onClick={() => setActivationStatusPopupVisibility(true)}
                                >
                                    <div>
              <span>
                {props.status?.profileStatus === PROFILE_STATUS.ACTIVE
                    ? 'Card Active'
                    : 'Card Inactive'}
              </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className={classes.ButtonWrapper}>
                        {(!props.isStatusDataLoading && props.status?.hpmEnabledForTenant) &&
                            <div className={classes.HighPrivacySwitch}>
                                <img src={PrivacyMode} alt={"privacy mode"}/>
                                <p>High Privacy <br/>Mode</p>
                                <Switch
                                    loading={props.isHpmStatusLoading}
                                    checked={props.status.highPrivacyMode}
                                    checkedChildren="ON"
                                    unCheckedChildren="OFF"
                                    onClick={handleSwitchClick}
                                />
                            </div>}
                        <button
                            style={{borderBottomColor: props.themeColor}}
                            className={classes.MyContactButton}
                            onClick={() => navigate(ROUTES_PATHS.USER.CONTACTS)}
                        >
                            <BiSolidUserRectangle size={15}/>
                            My Contacts
                        </button>
                    </div>

                </div>
            }
        </div>
    );
};

const mapStateToProps = state => {
    return {
        user: state.auth.user,
        vcardStatus: state.home.vcardStatus,
        isProfileStatusLoading: state.home.isProfileStatusLoading,
        isHpmStatusLoading: state.home.isHpmStatusLoading,
        themeColor: state.home.status.themeColor,
        logo: state.home.logo,
        collaboration: state.home.collaboration,
        status: state.home.status,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changePrivacyMode: (privacyMode) => dispatch(changePrivacyMode(privacyMode)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardHeader);
