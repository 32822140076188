import axios from '../../../common/axios';
import * as actionTypes from './actionTypes';

const getCompanySettingsSuccess = payload => {
    return {
        type: actionTypes.GET_COMPANY_SETTING_SUCCESS,
        payload,
    };
};

const getCompanySettingsFail = () => {
    return {
        type: actionTypes.GET_COMPANY_SETTING_FAIL,
    };
};

const getCompanySettingsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_SETTING_START,
    };
};

const updateCompanySettingsSuccess = payload => {
    return {
        type: actionTypes.UPDATE_COMPANY_SETTING_SUCCESS,
        payload,
    };
};

const updateCompanySettingsFail = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_SETTING_FAIL,
    };
};

export const getCompanySettings = () => {
    return async dispatch => {
        dispatch(getCompanySettingsStart());
        try {
            const response = await axios.get(
                'api/company-service/get-company-settings',
            );
            dispatch(getCompanySettingsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(getCompanySettingsFail(error.message));
            throw error;
        }
    };
};

export const updateCompanySettings = (data) => {
    return dispatch => {
        return axios
            .patch('api/company-service/update-company-settings', data)
            .then(res => {
                dispatch(updateCompanySettingsSuccess(data));
            }).catch(error => {
                dispatch(updateCompanySettingsFail());
            })
    };
};