import React, {useCallback, useEffect, useState} from 'react';
import {connect} from 'react-redux';
import classes from './BrandingDetailsNew.module.scss';
import commonClasses from '../Wrapper/Wrapper.module.scss';

import {useNavigate} from 'react-router-dom';
import {adjustColorBrightness, colorSetOne, colorSetTwo, createGradient} from 'shared/constants';
import * as actions from '../../../../../../store/actions';
import {uploadCompanyLogo} from '../../../../../../store/actions';

import {base64ToFile, getPreSignedTempUrl, showToast} from 'common/utils';
import {MdOutlineClose} from 'react-icons/md';
import {Spin} from 'antd';
import {useDropzone} from 'react-dropzone';
import {IoMdCloudUpload} from 'react-icons/io';
import imageCompression from 'browser-image-compression';
import {IoImageOutline} from 'react-icons/io5';
import ImageCropper from 'components/CreateProfile/ImageCropper/ImageCropper';
import {getBrandingDetails, updateBrandingDetails} from "../../../../../../store/actions/teams/teams";
import {BsPlus} from "react-icons/bs";
import Backdrop from "../../../../../UI/Backdrop/Backdrop";
import ColorPicker from "../../../Account/ColorPicker/ColorPicker";

const BrandingDetailsNew = props => {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [isReadOnly, setIsReadOnly] = useState(false);
    const navigate = useNavigate();
    const [adjustColor, setAdjustColor] = useState(null);

    const [darkMode, setDarkMode] = useState(true);
    const [socialMediaHidden, setSocialMediaHidden] = useState(false);
    const [themeColor, setThemeColor] = useState(props.themeColor);
    const [brandingLogo, setBrandingLogo] = useState(undefined);

    const [brandingLogoPreview, setBrandingLogoPreview] = useState(undefined);
    const [compressedImage, setCompressedImage] = useState(null);

    const [showCropper, setShowCropper] = useState(false);
    const [saveButtonIsClicked, setSaveButtonIsClicked] = useState(false);
    const [colorPickerIsOpen, setColorPickerIsOpen] = useState(false);

    const compressImage = async file => {
        if (file.type.split('/')[0] !== 'image') {
            showToast('Please select a valid image to compress', props.themeColor);
            return;
        }

        try {
            const compressedImage = await imageCompression(file, {
                maxSizeMB: 0.1, maxWidthOrHeight: 1280, useWebWorker: true,
            });

            const base64Result = await getBase64(compressedImage);
            setCompressedImage(base64Result);
            setShowCropper(true);
        } catch (error) {
            console.error('Image compression error: ', props.themeColor);
            showToast('Error compressing image', props.themeColor);
        }
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const onDrop = useCallback(acceptedFiles => {
        compressImage(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    useEffect(() => {
        setAdjustColor(adjustColorBrightness(themeColor, 60));
    }, []);

    useEffect(() => {
        const fetchBrandingDetails = async () => {
            if (!props.brandingDetails) {
                try {
                    setLoadingMessage('Loading');
                    setIsLoading(true);
                    await props.onGetBrandingDetails();
                } catch (error) {
                    console.error('Failed to load branding data:', error);
                    showToast('Failed to load branding data', props.themeColor);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setSocialMediaHidden(props.brandingDetails.socialMediaHidden);
                setDarkMode(props.brandingDetails.darkMode);
                setThemeColor(props.brandingDetails.themeColor);

                if (props.brandingDetails.brandingLogo) {
                    let logoUrl = props.brandingDetails.brandingLogo;
                    if (!logoUrl.includes('public')) {
                        logoUrl = await getPreSignedTempUrl(logoUrl);
                    }
                    setBrandingLogoPreview(logoUrl);
                }
            }
        };

        fetchBrandingDetails();
    }, [props.brandingDetails]);

    const renderCompanyLogoForDesktop = () => (<div
        className={classes.DropArea}
        {...(!isReadOnly ? getRootProps() : [])}
        style={{
            borderColor: isDragActive ? '#dbb659' : '#424242', cursor: isReadOnly ? 'no-drop' : 'pointer',
        }}
    >
        {' '}
        {!isReadOnly && <input {...getInputProps()} accept="image/*"/>}
        <div style={{flexDirection: 'column', gap: 24}}>
            <div className={classes.ImageContainer}>
                {brandingLogoPreview && !isReadOnly && (<div
                    className={classes.ClearButton}
                    onClick={e => {
                        setCompressedImage(null);
                        setBrandingLogo(null);
                        setBrandingLogoPreview(null);
                        e.stopPropagation();
                    }}
                >
                    <MdOutlineClose size={18} color="#C5C5C5"/>
                </div>)}
                {brandingLogoPreview ? (<img src={brandingLogoPreview} alt="Branding Logo" width={100}/>) : (
                    <div className={classes.IconContainer}>
                        <IoMdCloudUpload size={72} color="#919191"/>
                    </div>)}
            </div>

            {!isReadOnly && (<div style={{maxWidth: '240px', textAlign: 'center'}}>
                <p style={{margin: 0}}>
                    Drag & drop or click to select
                    your Branding Logo
                </p>
            </div>)}
        </div>
    </div>);

    const renderCompanyLogoForMobile = () => (<div className={classes.MobileProfilePictureContainer}>
        <div
            style={{
                background: `linear-gradient(91.3deg, ${adjustColor} 1.61%, ${createGradient(adjustColor, 50,)} 100%)`,
            }}
            className={classes.ProfilePicture}
            {...getRootProps()}
        >
            <div>
                {brandingLogoPreview && !isReadOnly && (<div
                    className={classes.ClearButton}
                    onClick={e => {
                        setCompressedImage(null);
                        setBrandingLogo(null);
                        setBrandingLogoPreview(null);
                        e.stopPropagation();
                    }}
                >
                    <MdOutlineClose size={16} color="#C5C5C5"/>
                </div>)}
                {brandingLogoPreview ? (<img src={brandingLogoPreview} alt="Profile"/>) : (
                    <IoImageOutline size={32} color="#929292"/>)}
            </div>
        </div>
        <p>Branding Logo</p>
    </div>);

    const uploadImage = async () => {
        let file;
        if (brandingLogo.includes(';base64')) {
            file = base64ToFile(brandingLogo, 'company-branding-logo');
        } else {
            file = brandingLogo;
        }

        try {
            setLoadingMessage('Uploading Branding Logo');
            setIsLoading(true);
            const uploadResult = await props.onUploadFile(file);
            return uploadResult.data;
        } catch (error) {
            showToast('Failed to upload branding logo', themeColor);
            console.log('error', error);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const onSaveHandler = async () => {
        setSaveButtonIsClicked(true);
        let logoUploadResult = null;

        let finalLogo = null;
        if (brandingLogo === undefined) finalLogo = props.brandingDetails?.brandingLogo;
        else if (brandingLogo === null) finalLogo = null;
        else {
            logoUploadResult = await uploadImage();

            if (logoUploadResult === null) {
                return;
            }

            finalLogo = logoUploadResult.url;
        }

        const requestData = {
            brandingLogo: finalLogo,
            themeColor: themeColor,
            socialMediaHidden: socialMediaHidden,
            darkMode: darkMode,
        };

        try {
            setLoadingMessage('Saving branding details');
            setIsLoading(true);

            await props.onUpdateBrandingDetails(requestData);
            showToast('Branding details updated successfully', themeColor);

        } catch (error) {
            showToast('Failed to save branding details', themeColor);
            console.log(error);
        } finally {
            setIsLoading(false);
            setSaveButtonIsClicked(false);
        }
    };

    return (<div className={classes.CreateProfile}>
        {showCropper && (<ImageCropper
            image={compressedImage}
            onChangeImage={image => {
                setCompressedImage(null);
                setBrandingLogo(image);
                setBrandingLogoPreview(image);
            }}
            onClose={() => setShowCropper(false)}
            themeColor={themeColor}
        />)}
        {colorPickerIsOpen ? (
            <Backdrop onClick={() => setColorPickerIsOpen(false)}>
                <ColorPicker
                    onClose={() => setColorPickerIsOpen(false)}
                    color={themeColor}
                    changeColor={setThemeColor}
                />
            </Backdrop>
        ) : null}
        {(props.loading || isLoading) && (<Spin
            tip={loadingMessage}
            size={'large'}
            style={{color: themeColor}}
            fullscreen
        />)}
        {/*<div className={classes.Header}></div>*/}

        <div className={commonClasses.Wrapper}>
            <div
                className={commonClasses.TopContainer}
                style={{justifyContent: 'center'}}
            >
                <div className={commonClasses.Title}>
                    <h1>Branding Details</h1>
                </div>
            </div>
            <div className={classes.MainContainer}>
                <div className={classes.CreateProfileFormContainer}>
                    {/* Branding Logo rendering */}
                    {renderCompanyLogoForDesktop()}
                    {renderCompanyLogoForMobile()}

                    <div className={classes.InputBox}>
                        <div>
                            <p className={classes.Label}>Shareable Profile Appearance</p>
                            <div style={{background: themeColor}} className={classes.InputWrapper}>
                                <div className={classes.Input}>
                                    <p
                                        className={classes.AccountStatus}>{darkMode ? "Dark Mode" : "Light Mode"}</p>
                                    <div
                                        className={classes.StatusSwitch}
                                        onClick={() => setDarkMode(!darkMode)}
                                        style={{justifyContent: darkMode ? "flex-end" : "flex-start"}}
                                    >
                                        <div className={classes.StatusNob} style={{backgroundColor: themeColor}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p className={classes.Label}>Social Media Links</p>
                            <div style={{background: themeColor}} className={classes.InputWrapper}>
                                <div className={classes.Input}>
                                    <p
                                        className={classes.AccountStatus}>{socialMediaHidden ? "Hidden" : "Shown"}</p>
                                    <div
                                        className={classes.StatusSwitch}
                                        onClick={() => setSocialMediaHidden(!socialMediaHidden)}
                                        style={{justifyContent: !socialMediaHidden ? "flex-end" : "flex-start"}}
                                    >
                                        <div className={classes.StatusNob} style={{backgroundColor: themeColor}}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.PaletteContainer}>
                    <div className={classes.DefaultColor}>
                        <p>Company Theme</p>
                        <p
                            style={{color: themeColor}}
                            onClick={() => setThemeColor('#DBB659')}
                        >
                            Reset color
                        </p>
                    </div>
                    <div className={classes.ColorPalette}>
                        {[...colorSetOne, ...colorSetTwo].map((color, index) =>
                            color === '' ? (
                                <div
                                    onClick={() => setColorPickerIsOpen(true)}
                                    className={classes.ColorPlus}
                                    key={`plus-${index}`}
                                >
                                    <BsPlus size={16} color="white"/>
                                </div>
                            ) : (
                                <div
                                    className={classes.Color}
                                    style={{
                                        backgroundColor: color,
                                        border: color === themeColor ? '2px solid white' : 'none'
                                    }}
                                    key={`color-${index}`}
                                    onClick={() => setThemeColor(color)}
                                />
                            ),
                        )}
                    </div>
                </div>

                <div
                    style={{background: themeColor}}
                    className={classes.MobilePaletteContainer}
                >
                    <div className={classes.MobilePalette}>
                        <div className={classes.DefaultColorSection}>
                            <p>Company Theme</p>
                            <p
                                style={{color: themeColor}}
                                onClick={() => setThemeColor('#DBB659')}
                            >
                                Reset color
                            </p>
                        </div>
                        <div className={classes.MobileColorPalette}>
                            <div>
                                {colorSetOne.map((color, index) =>
                                    color === '' ? (
                                        <div
                                            key={`color-plus-${index}`}
                                            className={classes.ColorPlus}
                                            onClick={() => setColorPickerIsOpen(true)}
                                        >
                                            <BsPlus size={16} color="white"/>
                                        </div>
                                    ) : (
                                        <div
                                            key={`color-${color}-${index}`}
                                            className={classes.Color}
                                            style={{
                                                backgroundColor: color,
                                                border: color === themeColor ? '2px solid white' : 'none'
                                            }}
                                            onClick={() => setThemeColor(color)}
                                        />
                                    ),
                                )}
                            </div>
                            <div>
                                {colorSetTwo.map((color, index) =>
                                    color === '' ? (
                                        <div
                                            key={`color-plus-${index}`}
                                            className={classes.ColorPlus}
                                            onClick={() => setColorPickerIsOpen(true)}
                                        >
                                            <BsPlus size={16} color="white"/>
                                        </div>
                                    ) : (
                                        <div
                                            key={`color-${color}-${index}`}
                                            className={classes.Color}
                                            style={{
                                                backgroundColor: color,
                                                border: color === themeColor ? '2px solid white' : 'none'
                                            }}
                                            onClick={() => setThemeColor(color)}
                                        />
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                    {!isReadOnly && (<div className={classes.ActionButtonsContainer}>
                        <button
                            style={{
                                background: `linear-gradient(91.3deg, ${themeColor} 1.61%, ${createGradient(themeColor, 50)} 100%)`,
                            }}
                            className={classes.SaveButton}
                            disabled={saveButtonIsClicked}
                            onClick={() => onSaveHandler()}
                        >
                            Save
                        </button>
                    </div>)}

                {!isReadOnly && (
                    <button
                        style={{
                            background: `linear-gradient(91.3deg, ${
                                props.themeColor
                            } 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                        }}
                        className={classes.SaveButtonMobile}
                        disabled={saveButtonIsClicked}
                        onClick={() => onSaveHandler()}
                    >
                        Save
                    </button>
                )}
            </div>
        </div>
    </div>)
        ;
};

const mapStateToProps = state => {
    return {
        themeColor: state.home.status.themeColor,
        loading: state.teams.loading,
        brandingDetails: state.teams.brandingDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetBrandingDetails: () => dispatch(getBrandingDetails()),
        onUploadFile: file => dispatch(actions.uploadFile(file)),

        onUpdateBrandingDetails: (data) => dispatch(updateBrandingDetails(data)),
        onUploadCompanyLogo: logo => dispatch(uploadCompanyLogo(logo)),
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(BrandingDetailsNew);
