import React, {useCallback, useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import classes from './CreateOrEditProfile.module.scss';
import {RiArrowLeftSLine} from 'react-icons/ri';
import ProfileCard from './ProfileCard/ProfileCard';
import Logo from '../../assets/images/commercial prime-33.svg';
import BackgroundTop from '../../assets/images/createProfile/backgroundTop.png';
import BackgroundBottom from '../../assets/images/createProfile/backgroundBottom.png';
import {IoImageOutline} from 'react-icons/io5';
import {useNavigate, useParams} from 'react-router-dom';
import AddFieldModal from './AddFieldModal/AddFieldModal';
import * as actions from '../../store/actions';
import {useDropzone} from 'react-dropzone';
import {adjustColorBrightness, createGradient} from 'shared/constants';
import PreviewConfirmation from './PreviewConfirmation/PreviewConfirmation';
import Backdrop from '../UI/Backdrop/Backdrop';
import DeleteConfirmation from './DeleteConfirmation/DeleteConfirmation';
import ExitConfirmation from './ExitConfirmation/ExitConfirmation';
import imageCompression from 'browser-image-compression';
import ImageCropper from './ImageCropper/ImageCropper';
import {MdDelete, MdOutlineClose} from 'react-icons/md';
import {DarkModeSwitch} from 'react-toggle-dark-mode';
import {Spin, Tooltip} from 'antd';
import {base64ToFile, getCountry, getPreSignedTempUrl, showToast, validateFields} from '../../common/utils';
import {
    DATA_FIELD_TYPE,
    PROFILE_DATA_FIELDS,
    PROFILE_DATA_PRIVACY_MODE,
    PROFILE_FIELD_LAYOUT_TYPE,
} from '../../common/enums';
import ChangeLayoutModal from './ChangeLayoutModal/ChangeLayoutModal';
import {IoMdCloudUpload} from 'react-icons/io';
import {getProfileData} from 'store/actions/home/home';
import {HttpStatusCode} from 'axios';
import PhoneInput from "react-phone-input-2";
import {FaXTwitter} from "react-icons/fa6";
import {IconPickerItem} from "react-fa-icon-picker";
import PrivacyModeDropdown from "./VisibilityDropdown/PrivacyModeDropdown";
import { openActiveProfile } from '../../common/utils';
import { changeActiveProfile } from '../../store/actions/home/home';

export const renderField = (field, index, fieldDisabled = false, isReadOnly, adjustColor, onUpdateProfileField, onRemoveProfileField) => {
    let inputElement;
    switch (field.type) {
        case DATA_FIELD_TYPE.CONTACT_NUMBER:
            inputElement = (
                <PhoneInput
                    dropdownStyle={{background: '#242424', color: 'white'}}
                    inputStyle={{
                        fontFamily: 'inherit',
                        fontSize: 'inherit',
                        background: 'transparent',
                        width: '100%',
                        border: 'none',
                    }}
                    country={getCountry()}
                    value={field.value}
                    placeholder={field.name}
                    onChange={value =>
                        onUpdateProfileField({name: field.name, value: value})
                    }
                    disabled={fieldDisabled || isReadOnly}
                />
            );
            break;
        case DATA_FIELD_TYPE.TEXTAREA:
            inputElement = (
                <textarea
                    placeholder={field.name}
                    rows={5}
                    style={{
                        height: '100%',
                        display: 'flex',
                        overflow: 'hidden',
                    }}
                    value={field.value}
                    onChange={e =>
                        onUpdateProfileField({name: field.name, value: e.target.value})
                    }
                    disabled={fieldDisabled || isReadOnly}
                />
            );
            break;
        case DATA_FIELD_TYPE.LINK:
            inputElement = (
                <input
                    placeholder={field.name}
                    type="text"
                    value={field.value}
                    maxLength={255}
                    onChange={e =>
                        onUpdateProfileField({name: field.name, value: e.target.value})
                    }
                    //onChange={e => linkChange(e, index, true)}
                    //onKeyDown={e => linkChange(e, index, true)}
                    disabled={fieldDisabled || isReadOnly}
                />
            );
            break;
        default:
            inputElement = (
                <input
                    placeholder={field.name}
                    type={field.type.toLowerCase() || 'text'}
                    value={field.value}
                    maxLength={255}
                    onChange={e =>
                        onUpdateProfileField({name: field.name, value: e.target.value})
                    }
                    disabled={fieldDisabled || isReadOnly}
                />
            );
    }

    return (
        <div
            key={field.name}
            className={classes.InputWrapper}
            style={{
                background: `linear-gradient(142deg, ${adjustColor} 0%, ${createGradient(
                    adjustColor,
                    80,
                )} 100%)`,
            }}
        >
            <div
                style={{
                    height:
                        field.type === DATA_FIELD_TYPE.TEXTAREA
                            ? '120px'
                            : '56px',
                    alignItems:
                        field.type === DATA_FIELD_TYPE.TEXTAREA
                            ? 'start'
                            : 'center',
                    gap: '8px',
                }}
            >
                {field.icon !== undefined &&
                    field.icon !== null &&
                    (field.icon === 'FaXTwitter' ? (
                        <FaXTwitter color="#919191" size={21}/>
                    ) : (
                        <IconPickerItem icon={field.icon} color="#919191" size={17}/>
                    ))}
                <Tooltip
                    title={
                        field.type === DATA_FIELD_TYPE.LINK
                            ? `${field.name} Link`
                            : field.name
                    }
                >
                    {inputElement}
                </Tooltip>

                <PrivacyModeDropdown
                    privacyMode={field.privacyMode}
                    onChange={value =>
                        onUpdateProfileField({name: field.name, privacyMode: value})
                    }
                    disabled={isReadOnly || !field.privacyModeToggleAllowed}
                />
            </div>
            {field.isCustom && (
                <Tooltip title={'Remove field'}>
                    <button
                        style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            padding: 10,
                        }}
                        onClick={() => onRemoveProfileField(field.name)}
                    >
                        <MdDelete size={20} color={'#919191'}/>
                    </button>
                </Tooltip>
            )}
        </div>
    );
};

const CreateOrEditProfile = props => {
    const navigate = useNavigate();
    const [loadingMessage, setLoadingMessage] = useState('loading');
    const {profileId} = useParams(); // Get profileId from URL
    const dispatch = useDispatch();
    const [showAddFieldModal, setShowAddFieldModal] = useState(false);
    const [showChangeLayoutModal, setShowChangeLayoutModal] = useState(false);
    const [showPreviewProfileConfirmation, setShowPreviewProfileConfirmation] =
        useState(false);
    const [isProfileDarkMode, setIsProfileDarkMode] = useState(true);
    const [showDeleteProfileConfirmation, setShowDeleteProfileConfirmation] =
        useState(false);
    const [showExitConfirmation, setShowExitConfirmation] = useState(false);
    const [previewConfirmationMessage, setPreviewConfirmationMessage] =
        useState(null);
    const [adjustColor, setAdjustColor] = useState(null);
    const [saveButtonIsClicked, setSaveButtonIsClicked] = useState(false);
    const [deleteButtonIsClicked, setDeleteButtonIsClicked] = useState(false);
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [isTouched, setIsTouched] = useState(false);

    console.log('props', props);

    const [profileFields, setProfileFields] = useState(
        Object.entries(PROFILE_DATA_FIELDS).map(
            ([
                 key,
                 {name, icon, type, privacyMode, privacyModeToggleAllowed, layout},
             ]) => ({
                id: null,
                name: name,
                icon: icon,
                type: type,
                privacyMode: privacyMode,
                privacyModeToggleAllowed: privacyModeToggleAllowed,
                value: '',
                layout: layout,
            }),
        ),
    );
    const [profilePicture, setProfilePicture] = useState(undefined);
    const [profilePicturePreview, setProfilePicturePreview] = useState(undefined);


    const leftProfileFields = [
        PROFILE_DATA_FIELDS.PROFILE_NAME.name,
        PROFILE_DATA_FIELDS.FIRST_NAME.name,
        PROFILE_DATA_FIELDS.LAST_NAME.name,
        PROFILE_DATA_FIELDS.COMPANY.name,
        PROFILE_DATA_FIELDS.TITLE.name,
        PROFILE_DATA_FIELDS.CONTACT_NUMBER.name,
        PROFILE_DATA_FIELDS.EMAIL.name,
        PROFILE_DATA_FIELDS.DESCRIPTION.name,
    ];
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setAdjustColor(adjustColorBrightness(props.themeColor, 60));
    }, []);

    useEffect(() => {
        if (props.mode === 'edit' && profileId) {
            setLoadingMessage('Loading Profile');
            setIsLoading(true);
            dispatch(getProfileData(profileId))
                .then(async profileData => {
                    const {profilePicture, filteredProfileFields} = profileData.data.reduce(
                        (acc, field) => {
                            if (field.name === 'Profile Picture') {
                                acc.profilePicture = field;
                            } else {
                                acc.filteredProfileFields.push(field);
                            }
                            return acc;
                        },
                        {profilePicture: null, filteredProfileFields: []}
                    );

                    setProfileFields([{
                        ...PROFILE_DATA_FIELDS.PROFILE_NAME,
                        value: profileData.name
                    }, ...filteredProfileFields]);

                    let profilePictureUrl = profilePicture.value;
                    if (profilePictureUrl == null || profilePictureUrl === '') {
                        setProfilePicturePreview(undefined);
                        return;
                    }
                    if (!profilePictureUrl.includes("public")) {
                        profilePictureUrl = await getPreSignedTempUrl(profilePictureUrl)
                    }
                    setProfilePicturePreview(profilePictureUrl);
                })
                .catch(error => {
                    console.error('Error: ', error);
                    showToast('Error fetching profile data', props.themeColor);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [props.mode, profileId, dispatch]);

    const onAddProfileField = data => {
        const updatedProfileFields = [...profileFields];

        updatedProfileFields.push();

        setProfileFields([
            ...profileFields,
            {
                id: null,
                name: data.fieldName,
                icon: data.fieldIcon,
                type: data.fieldType,
                value: '',
                privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
                privacyModeToggleAllowed: true,
                isCustom: true,
            },
        ]);
    };

    const onUpdateProfileField = updatedField => {
        setIsTouched(true);
        setProfileFields(prevFields =>
            prevFields.map(field =>
                field.name === updatedField.name ? {...field, ...updatedField} : field,
            ),
        );
    };

    const onUpdateProfileFields = updatedFields => {
        setProfileFields(prevFields =>
            prevFields.map(field => {
                const updatedField = updatedFields.find(f => f.name === field.name);
                return updatedField ? {...field, ...updatedField} : field;
            }),
        );
    };

    const onRemoveProfileField = fieldName => {
        setProfileFields(profileFields.filter(field => field.name !== fieldName));
    };
    //
    //   try {
    //     const compressedImage = await imageCompression(file, {
    //       maxSizeMB: 0.1,
    //       maxWidthOrHeight: 1920,
    //       useWebWorker: true,
    //     });
    //
    //     const base64Result = await getBase64(compressedImage);
    //     props.onChangeProfilePicture(base64Result);
    //     setShowCropper(true);
    //   } catch (error) {
    //     console.error('Image select error:', error);
    //   }
    // };
    //
    // const getBase64 = file => {
    //   return new Promise(resolve => {
    //     const reader = new FileReader();
    //     reader.readAsDataURL(file);
    //     reader.onload = () => resolve(reader.result);
    //   });
    // };

    const [compressedImage, setCompressedImage] = useState(null);
    const [showCropper, setShowCropper] = useState(false);

    const onDrop = useCallback(acceptedFiles => {
        setIsTouched(true);
        compressImage(acceptedFiles[0]);
    }, []);

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

    const compressImage = async file => {
        if (file.type.split('/')[0] !== 'image') {
            showToast('Please select a valid image to compress', props.themeColor);
            return;
        }

        try {
            const compressedImage = await imageCompression(file, {
                maxSizeMB: 0.1,
                maxWidthOrHeight: 1280,
                useWebWorker: true,
            });

            const base64Result = await getBase64(compressedImage);
            setCompressedImage(base64Result);
            setShowCropper(true);
        } catch (error) {
            console.error('Image compression error: ', props.themeColor);
            showToast('Error compressing image', props.themeColor);
        }
    };

    const getBase64 = file => {
        return new Promise(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
        });
    };

    const renderProfilePictureForDesktop = () => (
        <div
            className={classes.DropArea}
            {...(!isReadOnly ? getRootProps() : [])}
            style={{
                borderColor: isDragActive ? '#dbb659' : '#424242',
                cursor: isReadOnly ? 'no-drop' : 'pointer',
            }}
        >
            {' '}
            {!isReadOnly && <input {...getInputProps()} accept="image/*"/>}
            <div>
                <div className={classes.ImageContainer}>
                    {profilePicturePreview && !isReadOnly && (
                        <div
                            className={classes.ClearButton}
                            onClick={e => {
                                setIsTouched(true);
                                setCompressedImage(null);
                                setProfilePicture(null)
                                setProfilePicturePreview(null);
                                e.stopPropagation();
                            }}
                        >
                            <MdOutlineClose size={18} color="#C5C5C5"/>
                        </div>
                    )}
                    {profilePicturePreview ? (
                        <img src={profilePicturePreview} alt="Profile Picture"/>
                    ) : (
                        <div className={classes.IconContainer}>
                            <IoMdCloudUpload size={72} color="#919191"/>
                        </div>
                    )}
                </div>

                {!isReadOnly && (
                    <div style={{marginLeft: '32px', maxWidth: '240px'}}>
                        <p>
                            Drag & drop or click to select
                            your profile picture
                        </p>
                    </div>
                )}
            </div>
        </div>
    );

    const renderProfilePictureForMobile = () => (
        <div className={classes.MobileProfilePictureContainer}>
            <div
                style={{
                    background: `linear-gradient(91.3deg, ${adjustColor} 1.61%, ${createGradient(
                        adjustColor,
                        50,
                    )} 100%)`,
                }}
                className={classes.ProfilePicture}
                {...getRootProps()}
            >
                <div>
                    {profilePicturePreview && !isReadOnly && (
                        <div
                            className={classes.ClearButton}
                            onClick={e => {
                                setCompressedImage(null);
                                setProfilePicture(null)
                                setProfilePicturePreview(null);
                                e.stopPropagation();
                            }}
                        >
                            <MdOutlineClose size={16} color="#C5C5C5"/>
                        </div>
                    )}
                    {profilePicturePreview ? (
                        <img src={profilePicturePreview} alt="Profile"/>
                    ) : (
                        <IoImageOutline size={32} color="#929292"/>
                    )}
                </div>
            </div>
            <p>Profile Picture</p>
        </div>
    );

    const REQUIRED_FIELDS = [
        PROFILE_DATA_FIELDS.PROFILE_NAME.name,
        PROFILE_DATA_FIELDS.FIRST_NAME.name,
        PROFILE_DATA_FIELDS.LAST_NAME.name,
        PROFILE_DATA_FIELDS.CONTACT_NUMBER.name,
    ];

    const uploadProfilePicture = async () => {
        let file;
        if (profilePicture?.includes(';base64')) {
            file = base64ToFile(profilePicture, 'profile-picture');
        } else {
            file = profilePicture;
        }

        try {
            const uploadResult = await props.onUploadFile(file);
            return uploadResult.data;
        } catch (error) {
            showToast('error', 'Failed to upload profile picture', props.themeColor);
            return null;
        } finally {
            setIsLoading(false);
        }
    };

    const onDeleteButtonClick = () => {
        if (props.profileId == props.status.activeProfileId) {
            showToast("Cannot delete active profile", props.themeColor);
        } else {
            setShowDeleteProfileConfirmation(true)
        }
    }

    const onDeleteHandler = async () => {
        try {
            await props.onDeleteProfile(profileId);
            setShowDeleteProfileConfirmation(false);

            showToast('Profile deleted Successfully', props.themeColor);
            props.onClearProfileData();

            setTimeout(() => navigate(-1), 500);
        } catch (error) {
            showToast('Failed to delete the profile', props.themeColor);
        }
    };

    const onSaveHandler = async () => {
        const errors = validateFields(profileFields, REQUIRED_FIELDS);

        if (errors.length > 0) {
            errors.forEach(error => showToast(error, props.themeColor));
            return;
        }

        let profilePictureUploadResult = null;
        if (profilePicture) {
            setLoadingMessage('Uploading Profile Picture');
            setIsLoading(true);
            profilePictureUploadResult = await uploadProfilePicture();

            if (profilePictureUploadResult === null) {
                return;
            }
        }

        const data = profileFields.filter(
            field => field.name !== PROFILE_DATA_FIELDS.PROFILE_NAME.name,
        );
        data.push({
            id: null,
            name: 'Profile Picture',
            icon: 'FaImage',
            type: DATA_FIELD_TYPE.IMAGE,
            value: profilePictureUploadResult ? profilePictureUploadResult.url : null,
            privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
            privacyModeToggleAllowed: false,
            layout: PROFILE_FIELD_LAYOUT_TYPE.LIST,
        });

        const requestData = {
            name: profileFields.find(
                field => field.name === PROFILE_DATA_FIELDS.PROFILE_NAME.name,
            )?.value,
            darkMode: isProfileDarkMode,
            data,
        };

        try {
            setLoadingMessage('Saving Profile');
            setIsLoading(true);

            let result = null;

            if (props.mode === 'edit') {
                requestData.id = profileId;
                result = await props.onUpdateProfile(requestData);
            } else {
                result = await props.onSaveProfile(requestData);
            }

            if (result?.status === HttpStatusCode.Ok) {
                showToast('Profile saved successfully', props.themeColor);

                setTimeout(() => {
                    props.onClearProfileData();
                    navigate(-1);
                }, 500);
            }
        } catch (error) {
            showToast('Failed to save profile', props.themeColor);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={classes.CreateProfile}>

            {showPreviewProfileConfirmation && (
                <Backdrop onClick={() => setShowPreviewProfileConfirmation(false)}>
                    <PreviewConfirmation
                        color={props.themeColor}
                        onClose={() => setShowPreviewProfileConfirmation(false)}
                        onConfirmation={async () => {
                            await onSaveHandler();
                            await props.changeActiveProfile(props.profileId);
                            openActiveProfile(props.products);
                        }}
                        confirmationMessage={previewConfirmationMessage}
                    />
                </Backdrop>
            )}
            {showDeleteProfileConfirmation && (
                <Backdrop onClick={() => setShowDeleteProfileConfirmation(false)}>
                    <DeleteConfirmation
                        color={props.themeColor}
                        onClose={() => setShowDeleteProfileConfirmation(false)}
                        onConfirmation={onDeleteHandler}
                    />
                </Backdrop>
            )}
            {showExitConfirmation && (
                <Backdrop onClick={() => setShowExitConfirmation(false)}>
                    <ExitConfirmation
                        color={props.themeColor}
                        onClose={() => setShowExitConfirmation(false)}
                        onConfirmation={() => {
                            navigate(-1);
                        }}
                    />
                </Backdrop>
            )}
            {showCropper && (
                <Backdrop>
                    <ImageCropper
                        image={compressedImage}
                        onChangeImage={image => {
                            setCompressedImage(null);
                            setProfilePicture(image);
                            setProfilePicturePreview(image)
                        }} onClose={() => setShowCropper(false)}
                        themeColor={props.themeColor}
                    />
                </Backdrop>
            )}

            {isLoading && (
                <Spin
                    tip={loadingMessage}
                    size={'large'}
                    style={{color: props.themeColor}}
                    fullscreen
                />
            )}

            {/* Header */}
            <div className={classes.Header}>
                <div className={classes.BackButtonWrapper}>
                    <div
                        className={classes.BackButton}
                        onClick={() => isTouched ? setShowExitConfirmation(true) : navigate(-1)}
                    >
                        <RiArrowLeftSLine size={32}/>
                    </div>
                </div>
                <div className={classes.Title}>
                    <span>{props.mode === 'edit' ? 'Edit' : 'Create'} Profile</span>
                </div>
                {!isReadOnly && (
                    <div className={classes.ActionButtonsContainer}>
                        <button
                            className={classes.ChangeLayoutButton}
                            disabled={saveButtonIsClicked}
                            onClick={() => setShowChangeLayoutModal(true)}
                        >
                            Change Layout
                        </button>
                        {props.mode === 'edit' && (
                            <button
                                className={classes.DeleteButton}
                                disabled={deleteButtonIsClicked}
                                onClick={onDeleteButtonClick}
                            >
                                Delete
                            </button>
                        )}

                        <button
                            style={{
                                background: `linear-gradient(91.3deg, ${
                                    props.themeColor
                                } 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                            }}
                            className={classes.SaveButton}
                            disabled={saveButtonIsClicked}
                            onClick={() => onSaveHandler()}
                        >
                            Save
                        </button>
                    </div>
                )}
            </div>

            {/* Profile Card Preview */}
            <ProfileCard
                fullName={`${
                    profileFields.find(
                        f => f.name === PROFILE_DATA_FIELDS.FIRST_NAME.name,
                    ).value
                } ${
                    profileFields.find(f => f.name === PROFILE_DATA_FIELDS.LAST_NAME.name)
                        .value
                }`}
                title={
                    profileFields.find(f => f.name === PROFILE_DATA_FIELDS.TITLE.name)
                        .value
                }
                company={
                    profileFields.find(f => f.name === PROFILE_DATA_FIELDS.COMPANY.name)
                        .value
                }
                hidePreviewButton={props.mode !== 'edit'}
                color={props.themeColor}
                userRole={props.userRole}
                showConfirmation={() => {
                    const activeProfile = props.status.activeProfileId;
                    if (props.profileId === activeProfile.id) {
                        setPreviewConfirmationMessage(
                            "The profile you're editing will be saved with the current changes",
                        );
                    } else {
                        setPreviewConfirmationMessage(
                            "The profile you're editing will be saved and set as the active profile",
                        );
                    }
                    setShowPreviewProfileConfirmation(true);
                }}
            />

            {/* Main Content */}
            <div className={classes.Body}>
                <div
                    style={{backgroundColor: props.themeColor}}
                    className={classes.TopContainer}
                >
                    <img src={Logo} alt="Commercial Prime"/>
                    <img src={BackgroundTop} alt="Background Top"/>
                    <img src={BackgroundBottom} alt="Background Bottom"/>
                </div>

                <div className={classes.CreateProfileFormContainer}>
                    <div className={classes.CreateProfileForm}>
                        {/* Profile Picture Upload Area */}
                        {renderProfilePictureForDesktop()}
                        {renderProfilePictureForMobile()}

                        {!props.companyExists && (
                            <div className={classes.ThemeButton}>
                                <DarkModeSwitch
                                    checked={isProfileDarkMode}
                                    onChange={() => {
                                        const result = !isProfileDarkMode;
                                        setIsProfileDarkMode(result);
                                        showToast(
                                            `Current Profile set to ${
                                                result ? 'dark' : 'light'
                                            } mode`,
                                            props.themeColor,
                                        );
                                    }}
                                    size={25}
                                    sunColor={'white'}
                                />
                            </div>
                        )}

                        {/* Form Fields */}
                        <div className={classes.InputContainer}>
                            <div>
                                {profileFields
                                    .filter(field => leftProfileFields.includes(field.name))
                                    .map((field, index) => renderField(field, index, false, isReadOnly, adjustColor, onUpdateProfileField, onRemoveProfileField))}
                            </div>
                            <div>
                                {profileFields
                                    .filter(field => !leftProfileFields.includes(field.name))
                                    .map((field, index) => renderField(field, index, false, isReadOnly, adjustColor, onUpdateProfileField, onRemoveProfileField))}
                                {!isReadOnly && (
                                    <div
                                        className={classes.AddFieldButton}
                                        onClick={() => setShowAddFieldModal(true)}
                                    >
                                        <p>+ Add Field</p>
                                    </div>
                                )}
                                {!isReadOnly && (
                                    <>
                                        <button
                                            className={classes.ChangeLayoutButtonMobile}
                                            disabled={saveButtonIsClicked}
                                            onClick={() => setShowChangeLayoutModal(true)}
                                        >
                                            Change Layout
                                        </button>
                                        <button
                                            style={{
                                                background: `linear-gradient(91.3deg, ${
                                                    props.themeColor
                                                } 1.61%, ${createGradient(props.themeColor, 50)} 100%)`,
                                            }}
                                            className={classes.SaveButtonMobile}
                                            disabled={saveButtonIsClicked}
                                            onClick={() => onSaveHandler()}
                                        >
                                            Save
                                        </button>
                                        {props.mode === 'edit' && (
                                            <button
                                                className={classes.DeleteButtonMobile}
                                                onClick={onDeleteButtonClick}
                                            >
                                                Delete
                                            </button>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>

                    {showAddFieldModal && (
                        <AddFieldModal
                            setShowModal={setShowAddFieldModal}
                            themeColor={props.themeColor}
                            profileFields={profileFields}
                            onAddProfileField={onAddProfileField}
                        />
                    )}

                    {showChangeLayoutModal && (
                        <ChangeLayoutModal
                            setShowModal={setShowChangeLayoutModal}
                            themeColor={props.themeColor}
                            profileFields={profileFields.filter(
                                field =>
                                    field.privacyMode !== PROFILE_DATA_PRIVACY_MODE.PRIVATE,
                            )}
                            onUpdateProfileFields={onUpdateProfileFields}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};
const mapStateToProps = state => {
    return {
        user: state.auth.user,
        profiles: state.home.profiles,
        status: state.home.status,
        themeColor: state.home.status.themeColor,
        products: state.products.products,
        companyExists: state.home.companyExists,
        companyName: state.home.companyName,
        userRole: state.auth.userRole,
        companySettings: state.settings.data,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeActiveProfile: (profileId) => dispatch(changeActiveProfile(profileId)),
        onClearProfileData: () => dispatch(actions.clearProfileData()),
        onUploadFile: file => dispatch(actions.uploadFile(file)),
        onSaveProfile: requestData => dispatch(actions.saveProfile(requestData)),
        onUpdateProfile: (requestData, successCallback) =>
            dispatch(actions.updateProfile(requestData, successCallback)),
        onChangeActiveProfile: (profileId, showToast, callback) =>
            dispatch(actions.changeActiveProfile(profileId, showToast, callback)),
        onDeleteProfile: (profileId, successCallback, showToast) =>
            dispatch(actions.deleteProfile(profileId, successCallback, showToast)),
        onGetCompanyDetails: () => dispatch(actions.getCompanyDetails()),

    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CreateOrEditProfile);
