export const GET_COMPANY_USERS_START = 'GET_COMPANY_USERS_START';
export const GET_COMPANY_USERS_SUCCESS = 'GET_COMPANY_USERS_SUCCESS';
export const GET_COMPANY_USERS_FAIL = 'GET_COMPANY_USERS_FAIL';

export const GET_COMPANY_PRODUCTS_START = 'GET_COMPANY_PRODUCTS_START';
export const GET_COMPANY_PRODUCTS_SUCCESS = 'GET_COMPANY_PRODUCTS_SUCCESS';
export const GET_COMPANY_PRODUCTS_FAIL = 'GET_COMPANY_PRODUCTS_FAIL';

export const CHANGE_EXPAND = 'CHANGE_EXPAND';

export const UPDATE_COMPANY_USER_START = 'UPDATE_COMPANY_USER_START';
export const UPDATE_COMPANY_USER_SUCCESS = 'UPDATE_COMPANY_USER_SUCCESS';
export const UPDATE_COMPANY_USER_END = 'UPDATE_COMPANY_USER_END';

export const GET_ALL_TEAM_USER_PROFILES_START =
  'GET_ALL_TEAM_USER_PROFILES_START';
export const GET_ALL_TEAM_USER_PROFILES_SUCCESS =
  'GET_ALL_TEAM_USER_PROFILES_SUCCESS';
export const GET_ALL_TEAM_USER_PROFILES_FAIL =
  'GET_ALL_TEAM_USER_PROFILES_FAIL';

export const GET_COMPANY_DETAILS_START =
    'GET_COMPANY_DETAILS_START';
export const GET_COMPANY_DETAILS_SUCCESS =
    'GET_COMPANY_DETAILS_SUCCESS';
export const GET_COMPANY_DETAILS_FAIL = 'GET_COMPANY_DETAILS_FAIL';

export const GET_COMPANY_BRANDING_START =
    'GET_COMPANY_BRANDING_START';
export const GET_COMPANY_BRANDING_SUCCESS =
    'GET_COMPANY_BRANDING_SUCCESS';
export const GET_COMPANY_BRANDING_FAIL = 'GET_COMPANY_BRANDING_FAIL';

export const CLEAR_DATA = 'CLEAR_DATA';