import classes from './Settings.module.scss';
import {Spin, Switch} from 'antd';
import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {AiOutlineCheck, AiOutlineClose} from "react-icons/ai";
import * as actions from "../../../../../../store/actions";
import commonClasses from "../Wrapper/Wrapper.module.scss";
import {showToast} from "../../../../../../common/utils";


const Settings = (props) => {
    const [isSettingConfirmationModalOpen, setIsSettingConfirmationModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchCompanySettings = async () => {
            if (props.data == null) {
                try {
                    await props.onGetCompanySettings();
                } catch (error) {
                    console.error(error);
                    showToast("Failed to load settings", props.themeColor);
                }
            }
        };

        fetchCompanySettings();
    }, [props.data]);

    const updateCompanySettings = async (data) => {
        if (data) {
            try {
                setIsLoading(true);
                await props.onUpdateCompanySettings(data);
                // setSettings({
                //     ...settings,
                //     ...data,
                // });
                showToast("Setting updated successfully", props.themeColor);
            } catch (error) {
                // setSettings(props.data);
                console.error(error);
                showToast("Failed to update setting", props.themeColor);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div>
            {/*{isSettingConfirmationModalOpen && (*/}
            {/*  <Backdrop onClick={() => setIsSettingConfirmationModalOpen(false)}>*/}
            {/*    <ProfileSelfUpdateSettingConfirmation*/}
            {/*      enableProfileSelfUpdate={settings.enableProfileSelfUpdate}*/}
            {/*      onConfirm={() => {*/}
            {/*        props.updateCompanySettings({*/}
            {/*          enableProfileSelfUpdate: settings.enableProfileSelfUpdate*/}
            {/*        }, showToast)*/}
            {/*        setIsSettingConfirmationModalOpen(false)*/}
            {/*      }}*/}
            {/*      onClose={() => setIsSettingConfirmationModalOpen(false)}*/}
            {/*    />*/}
            {/*  </Backdrop>*/}
            {/*)}*/}


            <div className={commonClasses.Wrapper}>
                <div
                    className={commonClasses.TopContainer}
                    style={{justifyContent: 'center'}}
                >
                    <div className={commonClasses.Title}>
                        <h1>Settings</h1>
                    </div>
                </div>
                <div className={classes.MiddleContainer}>
                    {props.loading ? (
                        <Spin size={'large'} style={{color: props.themeColor}}/>
                    ) : props.data != null && <ul className={classes.SettingsList}>
                        {<li>
                            <div>
                                <Switch checkedChildren={<AiOutlineCheck/>} unCheckedChildren={<AiOutlineClose/>}
                                        disabled={isLoading}
                                        loading={isLoading}
                                        checked={props.data?.enableProfileSelfUpdate}
                                        onChange={(checked) => {
                                            // setSettings({...settings, enableProfileSelfUpdate: !checked});
                                            updateCompanySettings({enableProfileSelfUpdate: !props.data?.enableProfileSelfUpdate});
                                        }}/>

                            </div>
                            <p>Allow users of the company to update their profile information</p>
                        </li>}
                    </ul>}

                </div>
            </div>

        </div>
    );
}

const mapStateToProps = state => {
    return {
        data: state?.settings.data,
        loading: state?.settings.loading,
        themeColor: state.home.status.themeColor,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onGetCompanySettings: () => dispatch(actions.getCompanySettings()),
        onUpdateCompanySettings: (data) => dispatch(actions.updateCompanySettings(data)),
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Settings);
