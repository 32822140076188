export const ROUTES_PATHS = {
    PUBLIC: {
        REGISTER: "register",
        RESET_PASSWORD: "reset-password",
    },
    USER: {
        HOME: "home",
        ANALYTICS: "analytics",
        CONTACTS: "contacts",
        LINK: "link",
        ACCOUNT: "account",
        CREATE_PROFILE: "create-profile",
        EDIT_PROFILE: "edit-profile",
    },
    COMPANY_ADMIN: {
        TEAMS: "teams",
        USERS: "users",
        PROFILES: "profiles",
        CREATE_PROFILE: "create-profile",
        COMPANY_DETAILS: "company-details",
        BRANDING: "branding",
        SETTINGS: "settings",
    }
}

export const PROFILE_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

export const PROFILE_FIELD_LAYOUT_TYPE = {
    LIST: 'LIST',
    ICON: 'ICON',
}

export const PROFILE_PAGE_MODE = {
    CREATE: 'create',
    EDIT: 'edit',
}

export const USER_ROLE = {
    COMPANY_ADMIN: 'ROLE_COMPANY_ADMIN',
    COMPANY_USER: 'ROLE_COMPANY_USER',
    PLATFORM_USER: 'ROLE_PLATFORM_USER'
};

export const USER_STATUS = {
    ACTIVE: 'ACTIVE',
    INACTIVE: 'INACTIVE',
};

export const PROFILE_DATA_PRIVACY_MODE = {
    PRIVATE: 'PRIVATE',
    PUBLIC_BLOCK_EXPORT: 'PUBLIC_BLOCK_EXPORT',
    PUBLIC_ALLOW_EXPORT: 'PUBLIC_ALLOW_EXPORT',
};

export const DATA_FIELD_TYPE = {
    TEXT: 'TEXT',
    LINK: 'LINK',
    EMAIL: 'EMAIL',
    CONTACT_NUMBER: 'CONTACT_NUMBER',
    TEXTAREA: 'TEXTAREA',
    NUMBER: 'NUMBER',
    DATE: 'DATE',
    IMAGE: 'IMAGE',
    FILE: 'FILE',
}

export const PROFILE_DATA_FIELDS = {
    PROFILE_NAME: {
        name: 'Profile Name',
        icon: 'FaFolderMinus',
        type: DATA_FIELD_TYPE.TEXT,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PRIVATE,
        privacyModeToggleAllowed: false,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    FIRST_NAME: {
        name: 'First Name',
        icon: 'FaUser',
        type: DATA_FIELD_TYPE.TEXT,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: false,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    LAST_NAME: {
        name: 'Last Name',
        icon: 'FaUser',
        type: DATA_FIELD_TYPE.TEXT,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: false,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    COMPANY: {
        name: 'Company',
        icon: 'FaBriefcase',
        type: DATA_FIELD_TYPE.TEXT,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    TITLE: {
        name: 'Title',
        icon: 'FaAnchor',
        type: DATA_FIELD_TYPE.TEXT,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    CONTACT_NUMBER: {
        name: 'Contact Number',
        icon: 'FaPhone',
        type: DATA_FIELD_TYPE.CONTACT_NUMBER,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    EMAIL: {
        name: 'Email',
        icon: 'FaEnvelope',
        type: DATA_FIELD_TYPE.EMAIL,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    DESCRIPTION: {
        name: 'Description',
        icon: 'FaBolt',
        type: DATA_FIELD_TYPE.TEXTAREA,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    WEBSITE: {
        name: 'Website',
        icon: 'FaLink',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    WHATSAPP: {
        name: 'WhatsApp',
        icon: 'FaWhatsapp',
        type: DATA_FIELD_TYPE.CONTACT_NUMBER,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    FACEBOOK: {
        name: 'Facebook',
        icon: 'FaFacebook',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    INSTAGRAM: {
        name: 'Instagram',
        icon: 'FaInstagram',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    LINKEDIN: {
        name: 'LinkedIn',
        icon: 'FaLinkedin',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    X: {
        name: 'X',
        icon: 'FaXTwitter',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
};


export const COMPANY_DATA_FIELDS = {
    // LOGO: {
    //     name: 'Logo',
    //     icon: null,
    //     type: DATA_FIELD_TYPE.IMAGE,
    //     privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
    //     privacyModeToggleAllowed: true,
    //     layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    // },
    NAME: {
        name: 'Name',
        icon: 'FaUser',
        type: DATA_FIELD_TYPE.TEXT,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: false,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    ADDRESS: {
        name: 'Address',
        icon: 'FaMapMarkerAlt',
        type: DATA_FIELD_TYPE.TEXT,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: false,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    CONTACT_NUMBER: {
        name: 'Contact Number',
        icon: 'FaPhone',
        type: DATA_FIELD_TYPE.CONTACT_NUMBER,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    EMAIL: {
        name: 'Email',
        icon: 'FaEnvelope',
        type: DATA_FIELD_TYPE.EMAIL,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    WEBSITE: {
        name: 'Website',
        icon: 'FaLink',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.LIST
    },
    WHATSAPP: {
        name: 'WhatsApp',
        icon: 'FaWhatsapp',
        type: DATA_FIELD_TYPE.CONTACT_NUMBER,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    FACEBOOK: {
        name: 'Facebook',
        icon: 'FaFacebook',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    INSTAGRAM: {
        name: 'Instagram',
        icon: 'FaInstagram',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    LINKEDIN: {
        name: 'LinkedIn',
        icon: 'FaLinkedin',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
    X: {
        name: 'X',
        icon: 'FaXTwitter',
        type: DATA_FIELD_TYPE.LINK,
        privacyMode: PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT,
        privacyModeToggleAllowed: true,
        layout: PROFILE_FIELD_LAYOUT_TYPE.ICON
    },
};
