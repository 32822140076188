import * as actionTypes from '../actions/teams/actionTypes';

const initialState = {
    products: [],
    users: [],
    isProductDataLoading: false,
    isUserDataLoading: false,
    expanded: false,
    loading: false,
    dLoading: false,
    profileData: [],
    companyDetails: null,
    brandingDetails: null,
};

const getUsersStart = (state) => {
    return {...state, isUserDataLoading: true};
};

const getUsersSuccess = (state, action) => {
    return {...state, users: action.payload, isUserDataLoading: false};
};

const getUsersFail = (state) => {
    return {...state, users: [], isUserDataLoading: false};
};

const getProductsStart = (state) => {
    return {...state, isProductDataLoading: true};
};

const getProductsSuccess = (state, action) => {
    return {...state, products: action.payload, isProductDataLoading: false};
};

const getProductsFail = (state) => {
    return {...state, isProductDataLoading: false};
};

const setExpand = (state, action) => {
    return {
        ...state,
        ...action.payload,
    };
};

const getAllProfilesStart = (state, action) => {
    return {...state, loading: true};
};

const getAllProfilesSuccess = (state, action) => {
    return {
        ...state,
        ...action.payload,
        loading: false,
    };
};

const getAllProfilesFail = (state, action) => {
    return {...state, loading: false};
};

const getCompanyDetailsStart = state => {
    return {...state, loading: true, error: null};
};

const getCompanyDetailsSuccess = (state, action) => {
    return {
        ...state,
        loading: false,
        companyDetails: action.payload,
        error: null,
    };
};

const getCompanyDetailsFail = (state, action) => {
    return {...state, loading: false, error: action.payload};
};

const getBrandingDetailsStart = state => {
    return {...state, loading: true, error: null};
};

const getBrandingDetailsSuccess = (state, action) => {
    return {
        ...state,
        loading: false,
        brandingDetails: action.payload,
        error: null,
    };
};

const getBrandingDetailsFail = (state, action) => {
    return {...state, loading: false, error: action.payload};
};

const clearData = (state, action) => {
    return {
        ...state,
        loading: false,
        companyDetails: null,
        error: null,
    };
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_COMPANY_USERS_START:
            return getUsersStart(state, action);
        case actionTypes.GET_COMPANY_USERS_SUCCESS:
            return getUsersSuccess(state, action);
        case actionTypes.GET_COMPANY_USERS_FAIL:
            return getUsersFail(state, action);

        case actionTypes.GET_COMPANY_PRODUCTS_START:
            return getProductsStart(state, action);
        case actionTypes.GET_COMPANY_PRODUCTS_SUCCESS:
            return getProductsSuccess(state, action);
        case actionTypes.GET_COMPANY_PRODUCTS_FAIL:
            return getProductsFail(state, action);

        case actionTypes.GET_COMPANY_DETAILS_START:
            return getCompanyDetailsStart(state, action);
        case actionTypes.GET_COMPANY_DETAILS_SUCCESS:
            return getCompanyDetailsSuccess(state, action);
        case actionTypes.GET_COMPANY_DETAILS_FAIL:
            return getCompanyDetailsFail(state, action);

        case actionTypes.GET_COMPANY_BRANDING_START:
            return getBrandingDetailsStart(state, action);
        case actionTypes.GET_COMPANY_BRANDING_SUCCESS:
            return getBrandingDetailsSuccess(state, action);
        case actionTypes.GET_COMPANY_BRANDING_FAIL:
            return getBrandingDetailsFail(state, action);

        case actionTypes.CHANGE_EXPAND:
            return setExpand(state, {payload: {expanded: !state.expanded}});

        case actionTypes.GET_ALL_TEAM_USER_PROFILES_START:
            return getAllProfilesStart(state, action);
        case actionTypes.GET_ALL_TEAM_USER_PROFILES_SUCCESS:
            return getAllProfilesSuccess(state, action);
        case actionTypes.GET_ALL_TEAM_USER_PROFILES_FAIL:
            return getAllProfilesFail(state, action);

        case actionTypes.CLEAR_DATA:
            return clearData(state);
        default:
            return state;
    }
};

export default reducer;
