import * as actionTypes from './actionTypes';
import axios from '../../../common/axios';
import {signOut} from '../auth/auth';
import {getStatus} from "../home/home";

export const changeExpandState = () => {
    return {
        type: actionTypes.CHANGE_EXPAND,
    };
};

export const clearData = () => {
    return {
        type: actionTypes.CLEAR_DATA,
    };
};

const getUsersStart = () => {
    return {
        type: actionTypes.GET_COMPANY_USERS_START,
    };
};

const getUsersSuccess = (payload) => {
    return {
        type: actionTypes.GET_COMPANY_USERS_SUCCESS,
        payload,
    };
};

const getUsersFail = () => {
    return {
        type: actionTypes.GET_COMPANY_USERS_FAIL,
    };
};

const getProductsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_PRODUCTS_START,
    };
};

const getProductsSuccess = (payload) => {
    return {
        type: actionTypes.GET_COMPANY_PRODUCTS_SUCCESS,
        payload,
    };
};

const getProductsFail = () => {
    return {
        type: actionTypes.GET_COMPANY_PRODUCTS_FAIL,
    };
};

export const getUsers = () => {
    return dispatch => {
        dispatch(getUsersStart());
        axios.get('/api/company-service/get-users').then(res => {
            dispatch(getUsersSuccess(res.data));
        }).catch(() => dispatch(getUsersFail()));
    };
};

export const getProducts = () => {
    return dispatch => {
        dispatch(getProductsStart());
        axios.get('/api/company-service/get-products').then(res => {
            dispatch(getProductsSuccess(res.data));
        }).catch(() => dispatch(getProductsFail()));
    };
};

const updateUserStart = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_USER_START,
    };
};

export const updateUserSuccess = updatedUser => ({
    type: actionTypes.UPDATE_COMPANY_USER_SUCCESS,
    payload: updatedUser,
});

export const updateTeamsUserSuccess = (
    data,
    showToast,
    onClose,
    loggedInUser,
    pastData,
) => {
    return dispatch => {
        dispatch(updateUserStart());
        axios
            .post('/api/company-service/update-teams-user', data)
            .then(response => {
                if (response.status === 200) {
                    showToast('Successfully updated!');
                    const updatedUser = response.data;
                    dispatch(updateUserEnd());
                    dispatch(updateUserSuccess(updatedUser));
                    onClose();

                    if (
                        loggedInUser.username === pastData.username &&
                        loggedInUser.role !== data.role
                    ) {
                        setTimeout(() => {
                            showToast(
                                'User role of current user changed, ' + 'please login again!',
                            );
                            dispatch(signOut());
                        }, 500);
                    }
                }
            })
            .catch(e => {
                if (e.response.data.errorCode === 'PRODUCT_NOT_FOUND') {
                    showToast('Invalid QR Code!');
                } else if (
                    e.response.data.errorCode === 'INVALID_PRODUCT_REGISTRATION'
                ) {
                    showToast('QR Code already registered!');
                } else if (
                    e.response.data.errorCode ===
                    'COMPANY_SHOULD_HAVE_AT_LEAST_ONE_ACTIVE_ADMIN_ACCOUNT'
                ) {
                    showToast('Company should have at least one active admin account');
                } else {
                    showToast('Something went wrong!');
                }
                dispatch(updateUserEnd());
            });
    };
};

const updateUserEnd = () => {
    return {
        type: actionTypes.UPDATE_COMPANY_USER_END,
    };
};

const getAllProfilesStart = () => {
    return {
        type: actionTypes.GET_ALL_TEAM_USER_PROFILES_START,
    };
};

const getAllProfilesSuccess = payload => {
    return {
        type: actionTypes.GET_ALL_TEAM_USER_PROFILES_SUCCESS,
        payload,
    };
};

const getAllProfilesFail = () => {
    return {
        type: actionTypes.GET_ALL_TEAM_USER_PROFILES_FAIL,
    };
};

export const getAllTeamsUserProfiles = () => {
    return dispatch => {
        dispatch(getAllProfilesStart());
        axios
            .get('api/company-service/get-all-teams-profiles')
            .then(res => {
                dispatch(getAllProfilesSuccess({profileData: res.data}));
            })
            .catch(e => {
                dispatch(getAllProfilesFail());
                console.log(e);
            });
    };
};

const getCompanyDetailsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_DETAILS_START,
    };
};

const getCompanyDetailsSuccess = data => {
    return {
        type: actionTypes.GET_COMPANY_DETAILS_SUCCESS,
        payload: data,
    };
};
const getCompanyDetailsFail = error => ({
    type: actionTypes.GET_COMPANY_DETAILS_FAIL,
    payload: error,
});

export const getCompanyDetails = () => {
    return async dispatch => {
        dispatch(getCompanyDetailsStart());
        try {
            const response = await axios.get(
                '/api/company-service/get-company-details',
            );
            dispatch(getCompanyDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(getCompanyDetailsFail(error.message));
            throw error;
        }
    };
};

export const updateCompanyDetails = (requestData, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        return axios
            .post('/api/company-service/update-company-details', requestData)
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                dispatch(getCompanyDetails());
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

const getBrandingDetailsStart = () => {
    return {
        type: actionTypes.GET_COMPANY_DETAILS_START,
    };
};

const getBrandingDetailsSuccess = data => {
    return {
        type: actionTypes.GET_COMPANY_BRANDING_SUCCESS,
        payload: data,
    };
};
const getBrandingDetailsFail = error => ({
    type: actionTypes.GET_COMPANY_BRANDING_FAIL,
    payload: error,
});

export const getBrandingDetails = () => {
    return async dispatch => {
        dispatch(getBrandingDetailsStart());
        try {
            const response = await axios.get(
                '/api/company-service/get-branding-details',
            );
            dispatch(getBrandingDetailsSuccess(response.data));
            return response.data;
        } catch (error) {
            dispatch(getBrandingDetailsFail(error.message));
            throw error;
        }
    };
};

export const updateBrandingDetails = (requestData, successCallback = () => {
}, errorCallback = () => {
}) => {
    return dispatch => {
        return axios
            .post('/api/company-service/update-branding-details', requestData)
            .then(res => {
                console.log(res.data);
                successCallback(res.data);
                dispatch(getBrandingDetails());
                dispatch(getStatus());
                return res;
            })
            .catch(err => {
                console.error(err.response?.data || err.message);
                errorCallback();
                throw err;
            });
    };
};

export const uploadCompanyLogo = image => {
    return async () => {
        const token = JSON.parse(localStorage.getItem('prime'));

        const myHeaders = new Headers();
        myHeaders.append('Authorization', 'Bearer ' + token.token);

        const formData = new FormData();
        formData.append('file', image);

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formData,
        };

        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}api/company-service/uploadFile`,
                requestOptions,
            );
            return await response.text();
        } catch (error) {
            console.log('error', error);
            throw error;
        }
    };
};

export const createOrUpdateUser = (data, mode = "create") => {
    return dispatch => {
        return axios
            .post(mode === "edit" ? '/api/company-service/update-user' : '/api/company-service/add-user', data)
    };
};

export const deleteUser = (userId) => {
    return dispatch => {
        return axios
            .delete(`/api/company-service/delete-user?userId=${userId}`)
    };
};

export const createTeamProfile = (requestData, successCallback) => {
    return dispatch => {
        axios
            .post('/api/company-service/create-teams-user-profile', requestData)
            .then(() => {
                successCallback();
            })
            .catch(err => console.log(err.response.data));
    };
};
