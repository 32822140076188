import React, {useCallback, useRef, useState} from 'react';
import {MdOutlineClose} from 'react-icons/md';
import Cropper from 'react-cropper';
import {useLocation} from 'react-router-dom';
import {createGradient} from '../../../shared/constants';
import classes from './ImageCropper.module.scss';
import 'cropperjs/dist/cropper.css';

const ImageCropper = ({
                          onClose,
                          onChangeImage,
                          image,
                          themeColor
                      }) => {
    const location = useLocation();
    const [zoom, setZoom] = useState(1);
    const cropperRef = useRef(null);

    const isCompanyLogo = location.pathname === '/teams/company-details';

    const getCropData = useCallback(() => {
        if (cropperRef.current?.cropper) {
            return cropperRef.current.cropper.getCroppedCanvas().toDataURL();
        }
        return null;
    }, []);

    const handleDone = useCallback(() => {
        const croppedImage = getCropData();
        if (croppedImage) {
            onChangeImage(croppedImage);
        }
        onClose();
    }, [getCropData, onChangeImage, onClose]);

    const handleClose = useCallback(() => {
        onClose();
        onChangeImage(undefined);
    }, [onClose, onChangeImage]);

    const handleZoomChange = useCallback((e) => {
        setZoom(parseFloat(e.target.value));
    }, []);


    return (
        <div className={classes.ImageCropper}>
            <div className={classes.CloseButtonContainer}>
                <div style={{width: 30}}>
                </div>

                <h4 style={{flex: 1, textAlign: "center", color: "white", fontWeight: "normal"}}>Crop Image</h4>

                <button onClick={handleClose} style={{width: 30}}>
                    <MdOutlineClose size={24} color="#C5C5C5"/>
                </button>

            </div>
            <div className={classes.CropContainer}>
                <Cropper
                    ref={cropperRef}
                    style={{height: '100%', width: '100%', margin: "auto"}}
                    zoomTo={zoom}
                    aspectRatio={isCompanyLogo ? NaN : 1}
                    preview=".img-preview"
                    src={image}
                    viewMode={1}
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background={false}
                    responsive={true}
                    autoCropArea={1}
                    checkOrientation={false}
                    guides={true}
                    dragMode={isCompanyLogo ? 'crop' : 'move'}
                    cropBoxResizable={isCompanyLogo}
                    cropBoxMovable={isCompanyLogo}
                />
            </div>
            <div className={classes.Controls}>
                <input
                    type="range"
                    value={zoom}
                    min={0.5}
                    max={3}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={handleZoomChange}
                    className={classes.ZoomRange}
                    style={{'--thumb-bg-color': themeColor}}
                />
            </div>
            <div>
                <button
                    style={{
                        background: `linear-gradient(91.3deg, ${themeColor} 1.61%, ${createGradient(themeColor, 80)} 100%)`
                    }}
                    className={classes.DoneButton}
                    onClick={handleDone}
                >
                    Done
                </button>
            </div>
        </div>
    );
};

export default ImageCropper;