import React, {useEffect, useRef, useState} from 'react';
import {BsPlus} from 'react-icons/bs';
import {FiAnchor, FiCheckCircle, FiFileText, FiMail, FiUser, FiUserCheck} from 'react-icons/fi';
import {useOutletContext} from 'react-router-dom';
import classes from './Account.module.scss';
import Header from './Header/Header';
import * as actions from 'store/actions';
import {connect, useSelector} from 'react-redux';
import {ClipLoader} from 'react-spinners';
import {colorSetOne, colorSetTwo, createGradient} from 'shared/constants';
import Backdrop from 'components/UI/Backdrop/Backdrop';
import AccountUpdateConfirmationPopup from '../Account/AccountUpdateConfirmationPopup/AccountUpdateConfirmationPopup';
import ChangePasswordPopup from './ChangePasswordPopup/ChangePasswordPopup';
import BackgroundTop from 'assets/images/createProfile/backgroundTop.png';
import BackgroundBottom from 'assets/images/createProfile/backgroundBottom.png';
import ct from 'countries-and-timezones';
import ColorPicker from './ColorPicker/ColorPicker';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {AiFillCloseCircle} from 'react-icons/ai';
import {IoMdCheckmarkCircleOutline} from 'react-icons/io';
import ThemeColorLockPopup from './ThemeColorLockPopup/ThemeColorLockPopup';
import {isValidPhoneNumber, showToast} from 'common/utils';
import {Spin, Tooltip} from 'antd';
import {USER_ROLE} from "../../../../common/enums";

const Account = props => {
    const outletContext = useOutletContext();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [accountStatus, setAccountStatus] = useState('');

    const [updateConfirmPopupVisible, setUpdateConfirmPopupVisible] =
        useState(false);
    const [changePasswordPopupVisible, setChangePasswordPopupVisible] =
        useState(false);
    const [themeColorLockPopupVisible, setThemeColorLockPopupVisible] =
        useState(false);

    const [staticUsername, setStaticUsername] = useState('');
    const [colorPickerIsOpen, setColorPickerIsOpen] = useState(false);
    const [usernameExists, setUsernameExists] = useState(false);
    const [themeColor, setThemeColor] = useState(props.themeColor);

    const [isLoading, setIsLoading] = useState(false);
    const [isUpdating, setIsUpdating] = useState(false);
    const [initialUsername, setInitialUsername] = useState(username);

    const isAccountThemeLocked = (props.userRole === USER_ROLE.COMPANY_ADMIN || props.userRole === USER_ROLE.CORPORATE_USER);

    useEffect(() => {
        const fetchData = async () => {
            if (!props.accountDetails) {
                setIsLoading(true);
                try {
                    const fetchedData = await props.onGetAccountDetails();
                    console.log('Fetched data:', fetchedData);
                    setAccountData(fetchedData);
                    setIsLoading(false);
                } catch (error) {
                    showToast('Failed to load Data', themeColor);
                    console.error(error);
                } finally {
                    setIsLoading(false);
                }
            } else {
                setAccountData(props.accountDetails);
            }
        };

        fetchData();
    }, [props.accountDetails, props.onGetAccountDetails, themeColor]);

    const setAccountData = data => {
        setEmail(data.email);
        setFirstName(data.firstName);
        setLastName(data.lastName);
        setPhoneNumber(data.phoneNumber);
        setUsername(data.userName);
        setStaticUsername(data.userName);
        setInitialUsername(data.userName);
        setAccountStatus(data.status);
    };

    const checkUsername = async username => {
        try {
            if (username.length < 4) {
                setUsernameExists(true);
            } else {
                const response = await props.onIsUserNameExist(username);
                setUsernameExists(response);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const onUpdateHandler = async () => {
        const logoutStatus = username !== initialUsername;
        try {
            setIsUpdating(true);
            await props.onUpdateAccount(
                {
                    firstName,
                    lastName,
                    phoneNumber,
                    status: accountStatus,
                    themeColor: themeColor,
                    userName: username,
                },
                logoutStatus,
            )
            showToast("Account updated successfully", themeColor);
        } catch (e) {
            if (e.response.data.errorCode === "USERNAME_ALREADY_EXISTS") showToast("Username already exists", themeColor);
            else showToast("Something went wrong, please try again", themeColor);

        } finally {
            setIsUpdating(false);
        }
    };

    const getCountry = () => {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const obj = ct.getCountryForTimezone(tz);
        return obj.id.toLowerCase();
    };

    const formIsValid = () => {
        const firstNamePattern = /^[a-zA-Z]+$/;
        const lastNamePattern = /^[a-zA-Z]+$/;
        const usernamePattern = /^[a-zA-Z0-9]+$/;

        if (firstName?.trim() === '') {
            showToast('First name cannot be empty', themeColor);
            return false;
        }
        if (!firstNamePattern.test(firstName?.trim())) {
            showToast('First name should contain only letters', themeColor);
            return false;
        }
        if (lastName?.trim() === '') {
            showToast('Last name cannot be empty', themeColor);
            return false;
        }
        if (!lastNamePattern.test(lastName?.trim())) {
            showToast('Last name should contain only letters', themeColor);
            return false;
        }
        if (username?.trim() === '') {
            showToast('Username cannot be empty', themeColor);
            return false;
        }
        if (!usernamePattern.test(username?.trim())) {
            showToast('Username should not contain @, #, or spaces', themeColor);
            return false;
        }
        if (phoneNumber?.trim() === '') {
            showToast('Phone number cannot be empty', themeColor);
            return false;
        }

        if (!isValidPhoneNumber(phoneNumber)) {
            showToast('Invalid phone number', themeColor);
            return false;
        }

        return true;
    };

    const getTextColor = bgColor => {
        const luminance =
            0.2126 * parseInt(bgColor.substring(1, 3), 16) +
            0.7152 * parseInt(bgColor.substring(3, 5), 16) +
            0.0722 * parseInt(bgColor.substring(5, 7), 16);
        return luminance <= 0.7 ? '#ffffff' : '#000000';
    };

    const before = useSelector(state => state.auth.token);
    const after = useRef(before);

    useEffect(() => {
        if (after.current !== before) {
            onUpdateHandler();
        }

        after.current = before;
    }, [before]);

    const onGetPassword = value => {
        props.onSignIn('CommercialPrime', props.user, value, showToast);
    };

    const handlePhoneChange = phone => {
        setPhoneNumber(phone);
    };

    return (
        <div className={classes.Account}>
            {isLoading && (
                <Spin size={'large'} style={{color: themeColor}} fullscreen tip={'Loading'}/>
            )}
            <Header toggleMenu={outletContext.toggleMenu}/>
            {updateConfirmPopupVisible ? (
                <Backdrop onClick={() => setUpdateConfirmPopupVisible(false)}>
                    <AccountUpdateConfirmationPopup
                        color={themeColor}
                        onClose={() => {
                            setUpdateConfirmPopupVisible(false);
                        }}
                        getPassword={onGetPassword}
                    />
                </Backdrop>
            ) : null}
            {changePasswordPopupVisible ? (
                <Backdrop>
                    <ChangePasswordPopup
                        color={themeColor}
                        onClose={() => {
                            setChangePasswordPopupVisible(false);
                        }}
                    />
                </Backdrop>
            ) : null}

            {themeColorLockPopupVisible ? (
                <Backdrop onClick={() => setThemeColorLockPopupVisible(false)}>
                    <ThemeColorLockPopup
                        color={themeColor}
                        onClose={() => {
                            setThemeColorLockPopupVisible(false);
                        }}
                    />
                </Backdrop>
            ) : null}

            {colorPickerIsOpen ? (
                <Backdrop onClick={() => setColorPickerIsOpen(false)}>
                    <ColorPicker
                        onClose={() => setColorPickerIsOpen(false)}
                        color={themeColor}
                        changeColor={setThemeColor}
                    />
                </Backdrop>
            ) : null}
            <div
                style={{backgroundColor: themeColor}}
                className={classes.LeftContainer}
            >
                <img src={BackgroundTop} alt="commercial prime"/>
                <img src={BackgroundBottom} alt="commercial prime"/>
                <p style={{color: getTextColor(themeColor)}}>
                    Update your account details and personalize your theme to make it truly yours!
                </p>
            </div>

            <div className={classes.RightContainer}>
                <div className={classes.DetailContainer}>
                    <div className={classes.DetailRow}>
                        <div className={classes.Detail}>
                            <p className={classes.Label}>First Name</p>
                            <div
                                style={{background: themeColor}}
                                className={classes.InputWrapper}
                            >
                                <div className={classes.Input}>
                                    <div className={classes.IconContainer}>
                                        <FiUser size={18} color="#8d8d8d"/>
                                    </div>
                                    <input
                                        value={firstName}
                                        onChange={e => setFirstName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.Detail}>
                            <p className={classes.Label}>Last Name</p>
                            <div
                                style={{background: themeColor}}
                                className={classes.InputWrapper}
                            >
                                <div className={classes.Input}>
                                    <div className={classes.IconContainer}>
                                        <FiUser size={18} color="#8d8d8d"/>
                                    </div>
                                    <input
                                        value={lastName}
                                        onChange={event => setLastName(event.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.DetailRow}>
                        <div className={classes.Detail}>
                            <p className={classes.Label}>Username</p>
                            <div
                                style={{background: themeColor}}
                                className={classes.InputWrapper}
                            >
                                <div className={classes.Input}>
                                    <div className={classes.IconContainer}>
                                        <FiUserCheck size={18} color="#8d8d8d"/>
                                    </div>
                                    <input
                                        value={username}
                                        onChange={event => {
                                            setUsername(event.target.value);
                                            checkUsername(event.target.value);
                                        }}
                                    />
                                    <div className={classes.TickIcon}>
                                        {username.toLowerCase() ===
                                        staticUsername.toLowerCase() ? null : usernameExists ? (
                                            <Tooltip title={"Username is unavailable"}><AiFillCloseCircle size={18}
                                                                                                          color={'#ff3b3b'}/></Tooltip>
                                        ) : (
                                            <Tooltip title={"Username is available"}><IoMdCheckmarkCircleOutline
                                                size={18} color={'rgb(113 191 63)'}/></Tooltip>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={classes.Detail}>
                            <p className={classes.Label}>Email Address</p>
                            <div
                                style={{background: themeColor}}
                                className={classes.InputWrapper}
                            >
                                <div className={classes.Input}>
                                    <div className={classes.IconContainer}>
                                        <FiMail size={18} color="#8d8d8d"/>
                                    </div>
                                    <input
                                        value={email}
                                        disabled
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.DetailRow}>
                        <div className={classes.Detail}>
                            <p className={classes.Label}>Phone Number</p>
                            <div
                                style={{background: themeColor}}
                                className={classes.InputWrapper}
                            >
                                <div className={classes.Input}>
                                    <PhoneInput
                                        country={getCountry()}
                                        countryCodeEditable={false}
                                        value={phoneNumber}
                                        onChange={handlePhoneChange}
                                        enableSearch={true}
                                        disableSearchIcon={true}
                                        inputClass={classes.PhoneInput}
                                        buttonClass={classes.ButtonClass}
                                        dropdownClass={classes.DropdownClass}
                                        containerClass={classes.ContainerClass}
                                        searchClass={classes.SearchClass}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={classes.Detail}>
                            <p className={classes.Label}>Account Status</p>
                            <div
                                style={{background: themeColor}}
                                className={classes.InputWrapper}
                            >
                                <div className={classes.Input}>
                                    <div className={classes.IconContainer}>
                                        <FiCheckCircle size={18} color="#8d8d8d"/>
                                    </div>
                                    <input
                                        disabled
                                        value={accountStatus.charAt(0) +
                                            accountStatus.slice(1).toLowerCase()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.MobileDetailContainer}>
                    <div
                        style={{background: themeColor}}
                        className={classes.MobileInputWrapper}
                    >
                        <div className={classes.MobileInput}>
                            <div className={classes.IconContainer}>
                                <FiAnchor size={16} color="#8d8d8d"/>
                            </div>
                            <input
                                placeholder="First Name"
                                value={firstName}
                                onChange={event => setFirstName(event.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        style={{background: themeColor}}
                        className={classes.MobileInputWrapper}
                    >
                        <div className={classes.MobileInput}>
                            <div className={classes.IconContainer}>
                                <FiAnchor size={16} color="#8d8d8d"/>
                            </div>
                            <input
                                placeholder="Last Name"
                                value={lastName}
                                onChange={event => setLastName(event.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        style={{background: themeColor}}
                        className={classes.MobileInputWrapper}
                    >
                        <div className={classes.MobileInput}>
                            <div className={classes.IconContainer}>
                                <FiAnchor size={16} color="#8d8d8d"/>
                            </div>
                            <input
                                placeholder="User Name"
                                value={username}
                                onChange={event => setUsername(event.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        style={{background: themeColor}}
                        className={classes.MobileInputWrapper}
                    >
                        <div className={classes.MobileInput}>
                            <div className={classes.IconContainer}>
                                <FiMail size={16} color="#8d8d8d"/>
                            </div>
                            <input
                                placeholder="Email Address"
                                disabled={true}
                                value={email}
                                onChange={event => setEmail(event.target.value)}
                            />
                        </div>
                    </div>
                    <div
                        style={{background: themeColor}}
                        className={classes.MobileInputWrapper}
                    >
                        <div className={classes.MobileInput}>
                            <PhoneInput
                                country={getCountry()}
                                //countryCodeEditable={false}
                                value={phoneNumber}
                                onChange={handlePhoneChange}
                                enableSearch={true}
                                disableSearchIcon={true}
                                inputClass={classes.PhoneInput}
                                buttonClass={classes.ButtonClass}
                                dropdownClass={classes.DropdownClass}
                                searchClass={classes.SearchClass}
                            />
                        </div>
                    </div>
                    <div
                        style={{background: themeColor}}
                        className={classes.MobileInputWrapper}
                    >
                        <div className={classes.MobileInput}>
                            <div className={classes.MobileAccountStatusLabelContainer}>
                                <div className={classes.IconContainer}>
                                    <FiFileText size={16} color="#8d8d8d"/>
                                </div>
                                <p>Account Status</p>
                            </div>
                            <div className={classes.MobileAccountStatusContainer}>
                                <p className={classes.MobileAccountStatus}>
                                    {accountStatus.charAt(0) +
                                        accountStatus.slice(1).toLowerCase()}
                                </p>
                                {/*<div className={classes.StatusSwitch}>
                  <div className={classes.StatusNob}></div>
                </div>*/}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.PaletteContainer}>
                    <div className={classes.DefaultColor}>
                        <p>Account Theme</p>
                        <p
                            style={{color: themeColor}}
                            onClick={() => {
                                if (isAccountThemeLocked) {
                                    setThemeColorLockPopupVisible(true);
                                } else {
                                    setThemeColor('#DBB659');
                                }
                            }}
                        >
                            Reset color
                        </p>
                    </div>
                    <div className={classes.ColorPalette}>
                        {[...colorSetOne, ...colorSetTwo].map((color, index) =>
                            color === '' ? (
                                <div
                                    onClick={() => {
                                        if (isAccountThemeLocked) {
                                            setThemeColorLockPopupVisible(true);
                                        } else {
                                            setColorPickerIsOpen(true);
                                        }
                                    }}
                                    className={classes.ColorPlus}
                                    key={`plus-${index}`}
                                >
                                    <BsPlus size={16} color="white"/>
                                </div>
                            ) : (
                                <div
                                    className={classes.Color}
                                    style={{
                                        backgroundColor: color,
                                        border: color === themeColor ? '2px solid white' : 'none'
                                    }}
                                    key={`color-${index}`}
                                    onClick={() => {
                                        if (isAccountThemeLocked) {
                                            setThemeColorLockPopupVisible(true);
                                        } else {
                                            setThemeColor(color);
                                        }
                                    }}
                                />
                            ),
                        )}
                    </div>
                </div>

                <div
                    style={{background: themeColor}}
                    className={classes.MobilePaletteContainer}
                >
                    <div className={classes.MobilePalette}>
                        <div className={classes.DefaultColorSection}>
                            <p>Account Theme</p>
                            <p
                                style={{color: themeColor}}
                                onClick={() => {
                                    if (isAccountThemeLocked) {
                                        setThemeColorLockPopupVisible(true);
                                    } else {
                                        setThemeColor('#DBB659');
                                    }
                                }}
                            >
                                Reset color
                            </p>
                        </div>
                        <div className={classes.MobileColorPalette}>
                            <div>
                                {colorSetOne.map((color, index) =>
                                    color === '' ? (
                                        <div
                                            key={`color-plus-${index}`}
                                            className={classes.ColorPlus}
                                            onClick={() => {
                                                if (isAccountThemeLocked) {
                                                    setThemeColorLockPopupVisible(true);
                                                } else {
                                                    setColorPickerIsOpen(true);
                                                }
                                            }}
                                        >
                                            <BsPlus size={16} color="white"/>
                                        </div>
                                    ) : (
                                        <div
                                            key={`color-${color}-${index}`}
                                            className={classes.Color}
                                            style={{
                                                backgroundColor: color,
                                                border: color === themeColor ? '2px solid white' : 'none'
                                            }}
                                            onClick={() => {
                                                if (isAccountThemeLocked) {
                                                    setThemeColorLockPopupVisible(true);
                                                } else {
                                                    setThemeColor(color);
                                                }
                                            }}
                                        />
                                    ),
                                )}
                            </div>
                            <div>
                                {colorSetTwo.map((color, index) =>
                                    color === '' ? (
                                        <div
                                            key={`color-plus-${index}`}
                                            className={classes.ColorPlus}
                                            onClick={() => {
                                                if (props.companyExists) {
                                                    setThemeColorLockPopupVisible(true);
                                                } else {
                                                    setColorPickerIsOpen(true);
                                                }
                                            }}
                                        >
                                            <BsPlus size={16} color="white"/>
                                        </div>
                                    ) : (
                                        <div
                                            key={`color-${color}-${index}`}
                                            className={classes.Color}
                                            style={{
                                                backgroundColor: color,
                                                border: color === themeColor ? '2px solid white' : 'none'
                                            }}
                                            onClick={() => {
                                                if (props.companyExists) {
                                                    setThemeColorLockPopupVisible(true);
                                                } else {
                                                    setThemeColor(color);
                                                }
                                            }}
                                        />
                                    ),
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className={classes.BottomButtonContainer}>
                    <button
                        className={classes.ChangePasswordBtn}
                        style={{borderColor: themeColor}}
                        onClick={() => setChangePasswordPopupVisible(true)}
                    >
                        Change Password
                    </button>
                    <button
                        disabled={isUpdating}
                        style={{
                            background: `linear-gradient(91.3deg, 
                ${themeColor} 
                1.61%, 
                ${createGradient(themeColor, 50)} 
                100%)`,
                        }}
                        className={classes.UpdateDetailsBtn}
                        onClick={() => {
                            if (formIsValid()) {
                                const isUsernameChanged = username != initialUsername;
                                if (isUsernameChanged) {
                                    setUpdateConfirmPopupVisible(true);
                                } else {
                                    onUpdateHandler();
                                }
                            }
                        }}
                    >
                        {isUpdating ? (
                            <div className={classes.SpinnerContainer}>
                                <ClipLoader color={'white'} loading={true} size={20}/>
                            </div>
                        ) : (
                            <span>Update Details</span>
                        )}
                    </button>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        themeColor: state.home.status.themeColor,
        loading: state.account.loading,
        token: state.auth.token,
        user: state.auth.user,
        userRole: state.auth.userRole,
        accountDetails: state.account.accountDetails,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onGetAccountDetails: () => dispatch(actions.getAccountDetails()),
        onUpdateAccount: (accountDetails, logoutStatus) =>
            dispatch(
                actions.updateAccountDetails(accountDetails, logoutStatus),
            ),
        onSignIn: (tenantName, username, password, showToast) =>
            dispatch(actions.signIn(tenantName, username, password, showToast)),
        onSignOut: () => dispatch(actions.signOut()),
        onIsUserNameExist: username => dispatch(actions.isUserNameExist(username)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Account);
