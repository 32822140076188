import * as actionTypes from '../actions/settings/actionTypes'

const initialState = {
  data: null,
  loading: false,
};

const getCompanySettingsStart = (state, action) => {
  return {
    ...state,
    loading: true,
  };
}

const getCompanySettingsSuccess = (state, action) => {
  return {
    ...state,
    data: action.payload,
    loading: false,
  };
}

const getCompanySettingsFail = (state) => {
  return {
    ...state,
    loading: false,
  };
}

const updateCompanySettingsSuccess = (state, action) => {
  return {
    ...state,
    data: {...state.data, ...action.payload},
  };
}

const updateCompanySettingsFail = (state) => {
  return {
    ...state,
  };
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_COMPANY_SETTING_START:
      return getCompanySettingsStart(state);
    case actionTypes.GET_COMPANY_SETTING_SUCCESS:
      return getCompanySettingsSuccess(state, action);
    case actionTypes.GET_COMPANY_SETTING_FAIL:
      return getCompanySettingsFail(state);
    case actionTypes.UPDATE_COMPANY_SETTING_SUCCESS:
      return updateCompanySettingsSuccess(state, action);
    case actionTypes.UPDATE_COMPANY_SETTING_FAIL:
      return updateCompanySettingsFail(state);
    default:
      return state;
  }
};

export default reducer;