import {GetObjectCommand, S3Client} from '@aws-sdk/client-s3';
import {getSignedUrl} from '@aws-sdk/s3-request-presigner';
import ct from 'countries-and-timezones';
import {toast} from 'react-toastify';
import classes from '../pages/CreateOrEditProfilePage/CreateOrEditProfilePage.module.scss';
import {createGradient} from '../shared/constants';
import {DATA_FIELD_TYPE, PROFILE_DATA_FIELDS, PROFILE_DATA_PRIVACY_MODE} from './enums';
import {isValidNumber, parse} from "libphonenumber-js";

export const getPreSignedTempUrl = async link => {
    const s3 = new S3Client({
        region: process.env.REACT_APP_AWS_REGION,
        credentials: {
            accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
        },
        endpoint: process.env.REACT_APP_AWS_ENDPOINT,
    });

    const key = link.split('/').pop();

    const params = {
        Bucket: process.env.REACT_APP_AWS_BUCKET,
        Key: key,
        ResponseContentDisposition: 'inline',
    };

    const command = new GetObjectCommand(params);

    // Generate a signed URL valid for 600 seconds
    return await getSignedUrl(s3, command, {expiresIn: 600});
};

export const getCountry = () => {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const obj = ct.getCountryForTimezone(tz);
    return obj.id.toLowerCase();
};

export const showToast = (message, color = '#DBB659') => {
    toast(message, {
        theme: 'dark',
        position: 'top-center',
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        className: classes.CustomToast,
        bodyStyle: {
            color: "white",
            textAlign: 'center',
            padding: 6,
        },
        style: {
            padding: 12,
            background: `linear-gradient(142deg, ${color}, 40%, ${createGradient(
                color,
                80,
            )} 100%)`,
        },
    });
};

export const getLabelForPrivacyMode = privacyMode => {
    switch (privacyMode) {
        case PROFILE_DATA_PRIVACY_MODE.PRIVATE:
            return 'Private';
        case PROFILE_DATA_PRIVACY_MODE.PUBLIC_BLOCK_EXPORT:
            return 'Public - Block Export';
        case PROFILE_DATA_PRIVACY_MODE.PUBLIC_ALLOW_EXPORT:
            return 'Public - Allow Export';
        default:
            return '';
    }
};

export const base64ToFile = (base64String, defaultFileName = 'file') => {
    // Extract the MIME type and base64 data
    const matches = base64String.match(/^data:(image\/\w+);base64,/);
    if (!matches) {
        throw new Error('Invalid BASE64 string format');
    }

    const mimeType = matches[1];
    const extension = mimeType.split('/')[1];
    const byteString = atob(base64String.split(',')[1]);

    const byteNumbers = new Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
        byteNumbers[i] = byteString.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: mimeType});

    return new File([blob], `${defaultFileName}.${extension}`, {type: mimeType});
};

export const isValidPhoneNumber = phoneNumber => {
    const formattedNumber = phoneNumber.startsWith('+')
        ? phoneNumber
        : `+${phoneNumber}`;

    try {
        const parsedNumber = parse(formattedNumber);
        return isValidNumber(parsedNumber);
    } catch (e) {
        return false;
    }
};

export const validateFields = (fields, requiredFields) => {
    const errors = [];

    fields.forEach(field => {
        // Check for required fields
        if (requiredFields.includes(field.name) && !field.value) {
            errors.push(`${field.name} is required`);
        }

        // Validate first name and last name to contain only letters
        if (
            (field.name === PROFILE_DATA_FIELDS.FIRST_NAME.name ||
                field.name === PROFILE_DATA_FIELDS.LAST_NAME.name) &&
            field.value
        ) {
            const nameRegex = /^[A-Za-z]+$/; // Allows letters only
            if (!nameRegex.test(field.value)) {
                errors.push(`${field.name} must contain only letters`);
            }
            if (field.value.length > 100) {
                errors.push(`${field.name} must be 100 characters or less`);
            }
        }

        // Validate description field to have a max length (assuming 65535 characters for TEXT type)
        if (field.name === PROFILE_DATA_FIELDS.DESCRIPTION.name && field.value) {
            const maxDescriptionLength = 65535;
            if (field.value.length > maxDescriptionLength) {
                errors.push(`${field.name} must be ${maxDescriptionLength} characters or less`);
            }
        }

        // Validate links
        if (
            field.type === DATA_FIELD_TYPE.LINK &&
            field.value &&
            !field.value.startsWith('https://')
        ) {
            errors.push(`${field.name} link must start with https://`);
        }

        // Validate email addresses
        if (
            field.type === DATA_FIELD_TYPE.EMAIL &&
            field.value &&
            !/\S+@\S+\.\S+/.test(field.value)
        ) {
            errors.push(`${field.name} must be a valid email address`);
        }

        // Validate phone numbers
        if (
            field.type === DATA_FIELD_TYPE.CONTACT_NUMBER &&
            field.value
        ) {
            if (!isValidPhoneNumber(field.value)) {
                errors.push(`${field.name} must be a valid phone number`);
            }
        }
    });

    return errors;
};

export const openActiveProfile = (products) => {
    if (products.length > 0) {
        window.open(
            process.env.REACT_APP_SHAREABLE_VIEW_URL + '@' + products[0].link,
            '_blank',
        );
    }
};